import { format, isSameDay, parse } from "date-fns"
import { utcToZonedTime } from "date-fns-tz"

const devFilterSearchQueries = (searchQueries) => {
  if (
    process.env.NODE_ENV !== "development" ||
    !searchQueries ||
    searchQueries.length === 0
  )
    return false

  const ts1 = searchQueries.filter(({ team_size }) => team_size === "1")

  const currentYearAndMonth = format(new Date(), "yyyy-MM")

  const final = []
  let total = 0

  for (let i = 1; i < 30; i++) {
    const date = `${currentYearAndMonth}-${i}`
    const today = parse(date, "yyyy-MM-dd", new Date())

    const clientTimeNow = utcToZonedTime(today, "America/Toronto")

    const clientToday = parse(
      format(clientTimeNow, "yyyy-MM-dd h:mm a"),
      "yyyy-MM-dd h:mm a",
      new Date()
    )

    const search = ts1.filter(({ timestamp }) => {
      // console.log(today, utcToZonedTime(timestamp, "America/Toronto"))
      return isSameDay(
        clientToday,
        utcToZonedTime(timestamp, "America/Toronto")
      )
    })

    final[date] = search
    total += search.length
  }

  return true
}

export default devFilterSearchQueries
