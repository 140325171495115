import React, { useEffect, useState } from "react"
import { useQuery } from "react-query"
import { useAuth } from "../../../auth/useAuth"
import ProgressDots from "../../../components/ProgressDots"
import { chartOptions } from "../ProspectAnalytics/_config"
import { Line } from "react-chartjs-2"
import { DashboardCard } from "../index"
import { Col } from "reactstrap"
import { format, subMonths } from "date-fns"
import groupBy from "lodash/groupBy"

const monthIntArray = [...Array(6)].map((n, index) => 5 - index)

const labels = monthIntArray.map((n) => format(subMonths(new Date(), n), "MMM"))

const initialValues = monthIntArray.map((subMonthInt, index) => {
  return {
    shortName: format(subMonths(new Date(), subMonthInt), "MMM"),
    longName: format(subMonths(new Date(), subMonthInt), "MMMM"),
    monthMidDate: format(subMonths(new Date(), subMonthInt), "yyyy-MM-15"),
    subMonthInt,
    totalBookings: 0,
    repeatedlyBookedUsers: 0,
    transactions: 0,
    transactionValue: 0,
    averageOrderValue: 0,
    totalOrderValues: 0,
    totalRestaurantBookings: 0,
    bookings: [],
    webAppBookingsTotal: 0,
    restaurantBookingsTotal: 0,
    coworkingBookingsTotal: 0,
  }
})

const AppCharts = () => {
  const { makeRequest, isAuthenticated } = useAuth() || {}

  const [values, setValues] = useState(initialValues)

  const {
    isLoading,
    error,
    data = [],
    refetch,
  } = useQuery({
    queryKey: ["categoryData"],
    queryFn: () => makeRequest("fetch-app-bookings").then((res) => res || {}),
    enabled: isAuthenticated,
  })

  useEffect(() => {
    setValues(data?.formattedValues || initialValues)
  }, [data])

  if (isLoading) {
    return (
      <div className={"mb-5"}>
        <div className={"position-relative pb-3"}>
          <ProgressDots active />
        </div>
        <hr className={"mt-5"} />
      </div>
    )
  }

  return (
    <div className={"mb-5"}>
      <div className="row">
        <Chart
          data={[
            {
              label: "Bookings per month",
              data: values.map((v) => v.totalBookings),
            },

            {
              label: "Bookings - Web App",
              data: values.map((v) => v.webAppBookingCount),
            },
            {
              label: "Bookings - Mobile App",
              data: values.map((v) => v.mobileAppBookingCount),
            },
            {
              label: "Transactions per month",
              data: values.map((v) => v.transactions),
            },
          ]}
          title={"Bookings per month"}
        />
        <Chart
          data={[
            {
              label: "% of users",
              data: values.map((monthData) => {
                const userDataArray = monthData.bookings.map(
                  (b) => b.uid || b.user_email
                )

                const grouped = groupBy(userDataArray)
                let usersBookedMoreThanOnce = 0
                let activeUsers = 0

                Object.keys(grouped).forEach(function (key, index) {
                  if (grouped[key].length > 1) {
                    usersBookedMoreThanOnce += 1
                  }
                  if (grouped[key].length > 0) {
                    activeUsers += 1
                  }
                })

                return Number(
                  ((100 * usersBookedMoreThanOnce) / activeUsers).toFixed(1)
                )
              }),
            },
          ]}
          title={"Percentage of users who have booked more than once"}
        />
        <Chart
          data={[
            {
              label: "Total",
              data: values.map((v) => v.transactionValue?.total),
            },
            {
              label: "Web app",
              data: values.map((v) => v.transactionValue.web),
            },
            {
              label: "Mobile App",
              data: values.map((v) => v.transactionValue.app),
            },
            {
              label: "Restaurants & Coffee shops",
              data: values.map((v) => v.transactionValue.restaurants),
            },
            {
              label: "Coworking",
              data: values.map((v) => v.transactionValue.coworking),
            },
          ]}
          title={"Transaction value per month"}
        />

        <Chart
          data={[
            {
              label: "Average order value",
              data: values.map(
                (v) => v.totalOrderValues / v.totalRestaurantBookings
              ),
            },
          ]}
          title={"Average order value per booking"}
        />
      </div>
      <hr className={"mt-5"} />
    </div>
  )
}

export default AppCharts

const chartColors = [
  "#ecd295",
  "#f3a683",
  "#778beb",
  "#cf6a87",
  "#786fa6",
  "#e66767",
]

export const Chart = ({ title, data }) => {
  const datasets = data.map((d, index) => ({
    data: d?.data || [],
    label: d?.label || "Value",
    fill: false,
    backgroundColor: chartColors[index],
    borderColor: chartColors[index],
  }))
  return (
    <Col md={6}>
      <DashboardCard title={title}>
        <Line
          data={{
            datasets,
            labels: labels,
          }}
          options={{
            ...chartOptions,
            interaction: {
              mode: "index",
              axis: "y",
            },
            plugins: {
              legend: {
                display: true,
                position: "bottom",
              },
            },
          }}
        />
      </DashboardCard>
    </Col>
  )
}

const formatBookings = (data) => {
  let tempValues = [...initialValues]

  for (let i = 0; i < data.length; i++) {
    const b = data[i]
    tempValues = tempValues.map((monthData) => {
      if (monthData.longName !== format(new Date(b.date), "MMMM")) {
        return {
          ...monthData,
        }
      }

      const restaurantBooking =
        b.space.template_type !== "coworking-space" && b.sub_total > 0 ? 1 : 0

      const restaurantOrderValue = restaurantBooking
        ? Math.round(b.sub_total / 100)
        : 0

      const isWebAppBooking = b.source === "app.worksimply.com"
      const coworkingBooking =
        b.space.template_type === "coworking-space" && !isWebAppBooking

      return {
        ...monthData,
        totalBookings: monthData.totalBookings + 1,
        repeatedlyBookedUsers: 0,
        transactions:
          b.status === 2 ? monthData.transactions + 1 : monthData.transactions,
        transactionValue:
          monthData.transactionValue + Math.round(b.sub_total / 100),
        averageOrderValue: 0,
        totalOrderValues: monthData.totalOrderValues + restaurantOrderValue,
        totalRestaurantBookings:
          monthData.totalRestaurantBookings + restaurantBooking,
        bookings: [...monthData.bookings, b],
        webAppBookingsTotal: isWebAppBooking
          ? monthData.webAppBookingsTotal + Math.round(b.sub_total / 100)
          : monthData.webAppBookingsTotal,
        restaurantBookingsTotal:
          b.space.template_type !== "coworking-space" && !isWebAppBooking
            ? monthData.restaurantBookingsTotal + Math.round(b.sub_total / 100)
            : monthData.restaurantBookingsTotal,
        coworkingBookingsTotal: coworkingBooking
          ? monthData.coworkingBookingsTotal + Math.round(b.sub_total / 100)
          : monthData.coworkingBookingsTotal,
      }
    })
  }

  return tempValues
}
