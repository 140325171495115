import React from "react"
import { Col } from "reactstrap"
import { DashboardCard } from "../index"
import { Line } from "react-chartjs-2"
import { chartOptions } from "../ProspectAnalytics/_config"
import { format, subMonths } from "date-fns"
import { useQuery } from "react-query"
import { useAuth } from "../../../auth/useAuth"

const MembershipCharts = () => {
  const { makeRequest, isAuthenticated } = useAuth() || {}

  const { isLoading, data = [] } = useQuery({
    queryFn: () => makeRequest("metrics", {}, "get").then((res) => res || {}),
    enabled: isAuthenticated,
  })

  if (isLoading || !data?.charts || !data?.charts?.length) return null

  return (
    <div className={"row"}>
      {data?.primaryCharts?.map((chart) => {
        const initialData = chart?.data?.map((d) => d.value).reverse()
        const data = fillArray(initialData)

        return (
          <Chart
            key={chart?.id}
            data={[
              {
                label: chart.label,
                data,
              },
            ]}
            title={chart?.name}
          />
        )
      })}

      <div className="col-12">
        <hr className={"my-4 mb-5"} />
      </div>

      {data?.charts?.map((chart) => {
        const initialData = chart?.data?.map((d) => d.value).reverse()
        const data = fillArray(initialData)

        return (
          <Chart
            key={chart?.id}
            data={[
              {
                label: chart.label,
                data,
              },
            ]}
            title={chart?.name}
          />
        )
      })}
    </div>
  )
}

export default MembershipCharts

const Chart = ({ title, data }) => {
  const datasets = data.map((d, index) => ({
    data: d?.data || [],
    label: d?.label || "Value",
    fill: false,
    backgroundColor: "#ecd295",
    borderColor: "#ecd295",
  }))
  return (
    <Col md={6}>
      <DashboardCard title={title}>
        <Line
          data={{
            datasets,
            labels: labels,
          }}
          options={{
            ...chartOptions,
            interaction: {
              mode: "index",
              axis: "y",
            },
            plugins: {
              legend: {
                display: true,
                position: "bottom",
              },
            },
          }}
        />
      </DashboardCard>
    </Col>
  )
}

const monthIntArray = [...Array(12)].map((n, index) => 11 - index)

const labels = monthIntArray.map((n) =>
  format(subMonths(new Date(), n), "MMM yy")
)

const fillArray = (arr = []) => {
  if (!arr) return [...Array(12)]
  if (arr.length >= 12) return arr.slice(0, 12)
  arr.unshift(0)
  return fillArray(arr)
}
