export const colors = [
  "#575fcf",
  "#4bcffa",
  "#34e7e4",
  "#0be881",
  "#ffc048",
  "#ffdd59",
  "#ef5777",
  "#ff5e57",
  "#808e9b",
  "#485460",
]

export const chartOptions = {
  scales: {
    y: {
      beginAtZero: true,
    },
  },

  plugins: {
    tooltip: {
      mode: "index",
      intersect: false,
      padding: 10,
      bodyFont: {
        family: "futura-pt",
        size: 16,
      },
      titleFont: {
        family: "futura-pt",
        size: 14,
      },
    },
    legend: {
      display: true,
      position: "right",
      align: "start",
      labels: {
        boxWidth: 20,
        usePointStyle: true,
        pointStyle: "circle",
        font: {
          family: "futura-pt",
          size: 14,
        },
      },
    },
  },
}
