import React, { useEffect } from "react"
import Layout from "@components/layout"
import { navigate } from "gatsby"
import { useAuth } from "@auth/useAuth"
import { Card, CardBody } from "reactstrap"
import InventoryAnalytics from "./InventoryAnalytics"
import ProspectAnalytics from "./ProspectAnalytics"
import WorksimplyHome from "./WorksimplyHome"
import get from "lodash/get"

const HomeTemplate = () => {
  const { isWorksimplyAdmin, user, isAuthenticated } = useAuth() || {}

  useEffect(() => {
    if (!get(user, "id", false) || !isAuthenticated) return
    const dashboard_type = get(user, "space_operator.dashboard_type", "")
    if (!isWorksimplyAdmin) {
      if (!dashboard_type) {
        navigate("/locations")
      }
    }
  }, [user, isWorksimplyAdmin, isAuthenticated])

  if (isWorksimplyAdmin) {
    return <WorksimplyHome />
  }

  if (
    get(user, "space_operator.dashboard_type", "") === "inventory_analytics"
  ) {
    return <InventoryAnalytics />
  }
  if (get(user, "space_operator.dashboard_type", "") === "prospect_analysis") {
    return <ProspectAnalytics />
  }

  // No dashboard selected. The user will see the loading screen forever?
  return (
    <Layout title={"Dashboard"} progress={!get(user, "id", false)}>
      <p>Welcome to Worksimply</p>
    </Layout>
  )
}

export const DashboardCard = ({ title = null, text = null, children }) => {
  return (
    <Card className={"dashboard-card"}>
      <CardBody>
        {title && <h2 className={children ? "larger" : ""}>{title}</h2>}
        {children && <hr />}
        {text && <p className="single-line">{text}</p>}
        {children && <div className="card-inner">{children}</div>}
      </CardBody>
    </Card>
  )
}

export default HomeTemplate
