import { colors } from "./_config"

export const getDataForTotalBookings = (bookings, labels = []) => {
  return {
    labels,
    datasets: [
      {
        label: "Total Bookings",
        data: [
          bookings[labels[0]] || 0,
          bookings[labels[1]] || 0,
          bookings[labels[2]] || 0,
          bookings[labels[3]] || 0,
        ],
        fill: false,
        backgroundColor: colors[0],
        borderColor: colors[0],
      },
    ],
  }
}

export const getDataForTeamSize = (data, labels = []) => {
  return {
    labels,
    datasets: [
      {
        label: "With Team size",
        data: [
          data.withTeamSize[labels[0]] || 0,
          data.withTeamSize[labels[1]] || 0,
          data.withTeamSize[labels[2]] || 0,
          data.withTeamSize[labels[3]] || 0,
        ],
        fill: false,
        backgroundColor: colors[0],
        borderColor: colors[0],
      },
      {
        label: "Without Team size",
        data: [
          data.withoutTeamSize[labels[0]] || 0,
          data.withoutTeamSize[labels[1]] || 0,
          data.withoutTeamSize[labels[2]] || 0,
          data.withoutTeamSize[labels[3]] || 0,
        ],
        fill: false,
        backgroundColor: colors[1],
        borderColor: colors[1],
      },
    ],
  }
}

export const getDataForEachLocation = (data, labels = [], soName = null) => {
  const chartData = {
    labels,
    datasets: [],
  }

  for (let i = 0; i < data.length; i++) {
    const location = data[i]
    chartData.datasets.push({
      label: soName ? replace(location.label, soName) : location.label,
      data: [
        location.dataset[labels[0]] || 0,
        location.dataset[labels[1]] || 0,
        location.dataset[labels[2]] || 0,
        location.dataset[labels[3]] || 0,
      ],
      fill: false,
      backgroundColor: colors[i],
      borderColor: colors[i],
    })
  }

  return chartData
}

export const getAllOfficeBookings = (data) => {
  const { labels, allBookings } = data
  return {
    labels,
    datasets: [
      {
        label: "Total Bookings",
        data: [
          allBookings.reservationCount[labels[0]] || 0,
          allBookings.reservationCount[labels[1]] || 0,
          allBookings.reservationCount[labels[2]] || 0,
          allBookings.reservationCount[labels[3]] || 0,
        ],
        fill: false,
        backgroundColor: colors[0],
        borderColor: colors[0],
      },
    ],
  }
}

export const getValuesOfAllOfficeBookings = (data) => {
  const { labels, allBookings } = data
  return {
    labels,
    datasets: [
      {
        label: "Total reservation value",
        data: [
          allBookings.reservationValue[labels[0]] || 0,
          allBookings.reservationValue[labels[1]] || 0,
          allBookings.reservationValue[labels[2]] || 0,
          allBookings.reservationValue[labels[3]] || 0,
        ],
        fill: false,
        backgroundColor: colors[0],
        borderColor: colors[0],
      },
    ],
  }
}

export const getGroupedByLocation = (data, soName = null) => {
  const { labels, bookingsByLocation } = data
  const chartData = {
    labels,
    datasets: [],
  }

  for (let i = 0; i < bookingsByLocation.length; i++) {
    const booking = bookingsByLocation[i]
    chartData.datasets.push({
      label: soName ? replace(booking.label, soName) : booking.label,
      data: [
        booking.dataset.reservationCount[labels[0]] || 0,
        booking.dataset.reservationCount[labels[1]] || 0,
        booking.dataset.reservationCount[labels[2]] || 0,
        booking.dataset.reservationCount[labels[3]] || 0,
      ],
      fill: false,
      backgroundColor: colors[i],
      borderColor: colors[i],
    })
  }

  return chartData
}

export const getValueGroupedByLocation = (data, soName = null) => {
  const { labels, bookingsByLocation } = data
  const chartData = {
    labels,
    datasets: [],
  }

  for (let i = 0; i < bookingsByLocation.length; i++) {
    const booking = bookingsByLocation[i]
    chartData.datasets.push({
      label: soName ? replace(booking.label, soName) : booking.label,
      data: [
        booking.dataset.reservationValue[labels[0]] || 0,
        booking.dataset.reservationValue[labels[1]] || 0,
        booking.dataset.reservationValue[labels[2]] || 0,
        booking.dataset.reservationValue[labels[3]] || 0,
      ],
      fill: false,
      backgroundColor: colors[i],
      borderColor: colors[i],
    })
  }

  return chartData
}

const replace = (search, replace) => {
  if (!search || !replace) return ""
  const newName = search.replace(replace, "")
  return newName
}
