import React from "react"
import { Card, CardBody, CardHeader } from "reactstrap"

const Section = ({
  title = null,
  children,
  last = false,
  first = false,
  className = "",
}) => {
  return (
    <Card
      className={`${last ? "last" : ""} ${first ? "first" : ""} ${className}`}
    >
      {title && <CardHeader>{title}</CardHeader>}
      <CardBody>{children}</CardBody>
    </Card>
  )
}

export default Section
