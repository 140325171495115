import React, { forwardRef, useContext, useState } from "react"
import Layout from "@components/layout"
import { useAuth } from "../../auth/useAuth"
import OnlyAuthorized from "../../auth/OnlyAuthorized"
import { SpaceContext } from "../../hooks/SpaceContext"
import DatePicker from "react-datepicker"
import { addMonths, endOfDay, format, startOfDay, startOfMonth } from "date-fns"
import { templateTypes } from "../EditSpace/TabContent/Admin/TemplateType"
import { CSVLink } from "react-csv"
import AppCharts from "./AppCharts"
import MembershipCharts from "./MembershipCharts"
// import { SpaceContext } from "@hooks/SpaceContext"

const WorksimplyHome = () => {
  const { makeRequest, isAuthenticated, hasScope } = useAuth() || {}

  const {
    data: { spaceProviders },
  } = useContext(SpaceContext)

  const [progress, setProgress] = useState(false)
  const [bookings, setBookings] = useState([])

  const [state, setState] = useState({
    type: "",
    start: startOfMonth(new Date()),
    end: endOfDay(new Date()),
    so: "",
    product: "",
  })

  const onChange = (dates) => {
    const [start, end] = dates
    setState({
      ...state,
      start,
      end,
    })
  }

  const fetchBookingData = () => {
    setProgress(true)
    setBookings([])
    makeRequest("admin-filter-booking-data", {
      ...state,
      startDate: format(state.start, "yyyy-MM-dd"),
      endDate: format(state.end, "yyyy-MM-dd"),
    })
      .then((res) => {
        setBookings(res?.bookings || [])
      })
      .catch((e) => console.log(e))
      .finally(() => setProgress(false))
  }

  const CustomDatepickerInput = forwardRef(({ value, onClick }, ref) => (
    <input
      className="form-control"
      onClick={onClick}
      ref={ref}
      value={value}
      onChange={() => null}
    />
  ))

  return (
    <Layout title={"Dashboard"}>
      <OnlyAuthorized permission={"manage:space-providers"}>
        <MembershipCharts />
        <AppCharts />
        <div className="row">
          <div className="col-3">
            <label htmlFor="type">Type</label>
            <select
              className="form-control"
              id={"type"}
              name={"type"}
              value={state.type || ""}
              onChange={(e) =>
                setState({
                  ...state,
                  type: e.target.value,
                })
              }
            >
              <option value="">All</option>
              <option value="desk">Desks</option>
              <option value="offices">Day Offices</option>
              <option value="meeting-rooms">Meeting Rooms</option>
            </select>
          </div>
          <div className="col-3">
            <label htmlFor="product">Product</label>
            <select
              className="form-control"
              id={"product"}
              name={"product"}
              value={state.product || ""}
              onChange={(e) =>
                setState({
                  ...state,
                  product: e.target.value,
                })
              }
            >
              <option value="">All</option>
              <option value="pro">Pro</option>
              <option value="light">Light</option>
              <option value="platform">Platform</option>
              <option value="worksimply-app">Worksimply App</option>
            </select>
          </div>
          <div className="col-3">
            <label htmlFor="datepicker">Time period:</label>
            <DatePicker
              selected={state.start}
              onChange={onChange}
              startDate={state.start}
              endDate={state.end}
              selectsRange
              maxDate={state?.start ? addMonths(state.start, 3) : null}
              dateFormat="MMM dd, yyyy"
              customInput={<CustomDatepickerInput />}
            />
          </div>
          <div className="col-3">
            <label htmlFor="so">Space Operator</label>
            <select
              className="form-control"
              id={"so"}
              name={"so"}
              value={state?.so || ""}
              onChange={(e) =>
                setState({
                  ...state,
                  template_type: "",
                  so: e.target.value,
                })
              }
            >
              <option value="">All</option>
              {spaceProviders.map((so) => (
                <option value={so.id} key={so.id}>
                  {so.name}
                </option>
              ))}
            </select>
          </div>
          <div className="col-3 mt-4 mb-3">
            <label htmlFor="product">Template Type</label>
            <select
              className="form-control"
              id={"template_type"}
              name={"template_type"}
              value={state.template_type || ""}
              onChange={(e) =>
                setState({
                  ...state,
                  so: "",
                  template_type: e.target.value,
                })
              }
            >
              <option value="">All</option>
              {templateTypes.map(({ slug, label }) => (
                <option value={slug} key={slug}>
                  {label}
                </option>
              ))}
            </select>
          </div>
        </div>

        <button
          className="btn btn-primary mt-3 mb-5"
          onClick={fetchBookingData}
          disabled={progress || !state.start || !state.end}
        >
          Filter
        </button>

        {!progress && bookings.length === 0 ? (
          <p>No bookings for the selected criteria.</p>
        ) : (
          <Bookings bookings={bookings} />
        )}
      </OnlyAuthorized>
    </Layout>
  )
}

export default WorksimplyHome

const headers = [
  { label: "Reservation ID", key: "id" },
  { label: "Date", key: "date" },
  { label: "User Email", key: "user_email" },
  { label: "Total", key: "total" },
  { label: "SO", key: "so" },
]

const Bookings = ({ bookings = [] }) => {
  if (!bookings || bookings.length === 0) {
    return null
  }

  const formattedBookings = bookings.map((b) => {
    const { id, user_email, total } = b
    const date = b?.date?.split("T")?.[0] || "N/A"
    return {
      id,
      date,
      user_email,
      total: `$${(total / 100).toFixed(2)}`,
      so: b?.so?.name,
    }
  })

  return (
    <div>
      <h4>
        Total Transaction Value: $
        {bookings
          .reduce((total, current) => total + Number(current.total) / 100, 0)
          .toLocaleString()}
        <br />
        Total Bookings: {bookings.length}
      </h4>

      <CSVLink
        headers={headers}
        data={formattedBookings}
        filename={`worksimply-report.csv`}
        className="btn btn-primary btn-sm ml-auto"
      >
        Download CSV
      </CSVLink>

      <table className="table table-sm table-hover my-5">
        <thead>
          <tr>
            {headers.map((h) => (
              <th key={h.label}>{h.label}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {bookings.map((b) => {
            const dt = b?.date?.split("T")?.[0] || "N/A"
            return (
              <tr key={b.id}>
                <td>
                  {b?.pdf_url ? (
                    <a
                      target={"_blank"}
                      href={b.pdf_url}
                      className={"text-dark"}
                      rel={"noreferrer"}
                    >
                      {b.id}
                    </a>
                  ) : (
                    b.id
                  )}
                </td>
                <td>{dt}</td>
                <td>{b.user_email}</td>
                <td>$ {(b.total / 100).toFixed(2)}</td>
                <td>{b?.so?.name || "N/A"}</td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}
