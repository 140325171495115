import React, { useContext, useEffect, useState } from "react"
import { useAuth } from "@auth/useAuth"
import { Chart as ChartJS, registerables } from "chart.js"
import { Line } from "react-chartjs-2"
import { SpaceContext } from "@hooks/SpaceContext"
import ProgressDots from "../../../components/ProgressDots"
import { DashboardCard } from "../index"
import get from "lodash/get"
import {
  getAllOfficeBookings,
  getDataForEachLocation,
  getDataForTeamSize,
  getDataForTotalBookings,
  getGroupedByLocation,
  getValueGroupedByLocation,
  getValuesOfAllOfficeBookings,
} from "./_getData"
import { chartOptions } from "./_config"

ChartJS.register(...registerables)

const ProspectAnalytics = () => {
  const { makeRequest, isAuthenticated } = useAuth() || {}
  const [progress, setProgress] = useState(false)

  const [chartLabels, setChartLabels] = useState([])

  const [allToursChartData, setAllToursChartData] = useState({})
  const [toursByTeamSize, setToursByTeamSize] = useState({})

  const [bookingsByLocation, setBookingsByLocation] = useState({})

  const [officeBookings, setOfficeBookings] = useState({})

  const {
    data: { tourChartData, officeChartData, soData },
    actions: { setValues },
  } = useContext(SpaceContext)

  const [monthlyChartsEnabled, setMonthlyChartsEnabled] = useState(false)
  const [onDemandChartsEnabled, setOnDemandChartsEnabled] = useState(false)

  const [totalInquiriesChartUrl, setTotalInquiriesChartUrl] = useState(null)
  const [inquiriesTsChartUrl, setInquiriesTsChartUrl] = useState(null)

  useEffect(() => {
    if (!get(soData, "id", false)) return
    setMonthlyChartsEnabled(soData.monthly_offices_enabled)
    setOnDemandChartsEnabled(
      soData.on_demand_offices_daily_enabled ||
        soData.on_demand_offices_hourly_enabled ||
        soData.day_passes_enabled
    )
  }, [soData])

  const fetchData = () => {
    if (!isAuthenticated) return
    setProgress(true)
    makeRequest("dashboard-chart-data")
      .then((res) => {
        const { tourBookings, officeBookings } = res
        setChartLabels(get(tourBookings, "labels", []))
        setValues({
          tourChartData: tourBookings,
          officeChartData: officeBookings,
        })
      })
      .catch((e) => {
        console.log(e)
      })
  }

  useEffect(() => {
    if (!isAuthenticated || (tourChartData && tourChartData.labels)) return
    fetchData()
  }, [isAuthenticated])

  useEffect(() => {
    if (
      !chartLabels ||
      chartLabels.length === 0 ||
      !tourChartData ||
      !soData ||
      !soData.name
    ) {
      return
    }
    const totalBookings = get(
      tourChartData,
      "bookingSummary.totalBookings",
      null
    )
    if (totalBookings) {
      setAllToursChartData(getDataForTotalBookings(totalBookings, chartLabels))
    }
    setToursByTeamSize(
      getDataForTeamSize(tourChartData.bookingSummary, chartLabels)
    )

    setBookingsByLocation(
      getDataForEachLocation(
        tourChartData.bookingsByLocation,
        chartLabels,
        soData.name
      )
    )

    setOfficeBookings({
      totalCount: getAllOfficeBookings(officeChartData),
      totalValue: getValuesOfAllOfficeBookings(officeChartData),
      groupedByLocation: getGroupedByLocation(officeChartData, soData.name),
      valueGroupedByLocation: getValueGroupedByLocation(
        officeChartData,
        soData.name
      ),
    })
    setProgress(false)

    if (soData.id === "6098a671-e325-4e72-b29f-82dd4ddd8dd2") {
      // set WP1
      setTotalInquiriesChartUrl(
        "https://analytics.amplitude.com/share/embed/070bc25c-e78e-43d1-9cd8-e15be90e0c59"
      )
      setInquiriesTsChartUrl(
        "https://analytics.amplitude.com/share/embed/4cb6521b-90a7-4e26-b74a-c6d6f460551c"
      )
    } else if (soData.id === "f3fad535-47db-40e6-bf09-e95d1084642c") {
      // set IQ
      setTotalInquiriesChartUrl(
        "https://analytics.amplitude.com/share/embed/fd5e6e42-2ce7-4e02-a2fd-1e93e4922c33"
      )
      setInquiriesTsChartUrl(
        "https://analytics.amplitude.com/share/embed/fde89450-d293-4cbe-b356-6431e21b1245"
      )
    }
  }, [tourChartData, officeChartData, chartLabels, soData])

  if (!tourChartData || progress || !get(allToursChartData, "labels", "")) {
    return (
      <div className={"position-relative py-5"}>
        <ProgressDots active />
      </div>
    )
  }

  return (
    <div className={"row"}>
      {monthlyChartsEnabled && (
        <>
          <div className="col-md-6">
            <DashboardCard title={"Tours booked for all locations"}>
              <Line data={allToursChartData} options={chartOptions} />
            </DashboardCard>
          </div>
          <div className="col-md-6">
            <DashboardCard title={"Tours booked based on team size"}>
              <Line data={toursByTeamSize} options={chartOptions} />
            </DashboardCard>
          </div>
          <div className="col-md-6">
            <DashboardCard title={"Tours booked per location"}>
              <Line data={bookingsByLocation} options={chartOptions} />
            </DashboardCard>
          </div>

          <div className="col-md-12" />

          {totalInquiriesChartUrl && (
            <div className="col-md-6">
              <DashboardCard title={"Inquiries for all location"}>
                <iframe
                  className="frame"
                  src={totalInquiriesChartUrl}
                  title={"Total Inquiries Chart"}
                  width="100%"
                  height="500"
                />
              </DashboardCard>
            </div>
          )}

          {inquiriesTsChartUrl && (
            <div className="col-md-6">
              <DashboardCard title={"Inquiries based on Team Size"}>
                <iframe
                  className="frame"
                  src={inquiriesTsChartUrl}
                  title={"Inquiries based on Team Size"}
                  width="100%"
                  height="500"
                />
              </DashboardCard>
            </div>
          )}
        </>
      )}

      {onDemandChartsEnabled && 1 === 2 && (
        <>
          <div className="col-md-12">
            <hr className={"mt-4 mb-5"} />

            <h3 className={"mb-3"}>On-demand Bookings</h3>
          </div>

          <div className="col-md-6">
            <DashboardCard title={"Bookings for all location"}>
              <Line data={officeBookings.totalCount} options={chartOptions} />
            </DashboardCard>
          </div>
          <div className="col-md-6">
            <DashboardCard title={"Total reservation value"}>
              <Line data={officeBookings.totalValue} options={chartOptions} />
            </DashboardCard>
          </div>
          <div className="col-md-6">
            <DashboardCard title={"Bookings per location"}>
              <Line
                data={officeBookings.groupedByLocation}
                options={chartOptions}
              />
            </DashboardCard>
          </div>
          <div className="col-md-6">
            <DashboardCard title={"Total reservation value per location"}>
              <Line
                data={officeBookings.valueGroupedByLocation}
                options={chartOptions}
              />
            </DashboardCard>
          </div>
        </>
      )}
    </div>
  )
}

export default ProspectAnalytics
