import React, { useContext, useEffect, useState } from "react"
import { Bar } from "react-chartjs-2"
import { DashboardCard } from "../index"
import Layout from "@components/layout"
import Analytics from "./Analytics"
import devFilterSearchQueries from "../devFilterSearchQueries"
import { SpaceContext } from "@hooks/SpaceContext"
import themeConfig from "../../../config/themeConfig"

const ProspectAnalytics = () => {
  const {
    data: { searchQueries, toursBooked, locationsCategorizedBySpaceOperator },
  } = useContext(SpaceContext)

  const [mp, setMp] = useState(null)
  const [mpList, setMpList] = useState([])
  const [nr, setNr] = useState(null)
  const [graphList, setGraphList] = useState([])

  useEffect(() => {
    if (
      locationsCategorizedBySpaceOperator &&
      locationsCategorizedBySpaceOperator.length > 0 &&
      locationsCategorizedBySpaceOperator[0].spaces &&
      locationsCategorizedBySpaceOperator[0].spaces.length > 0 &&
      toursBooked &&
      toursBooked.length > 0
    ) {
      // all available

      const list = locationsCategorizedBySpaceOperator[0].spaces

      let graphListT = []
      for (let i = 0; i < list.length; i++) {
        const space = list[i]
        let count = 0
        for (let j = 0; j < toursBooked.length; j++) {
          const tour = toursBooked[j]
          if (space.id === tour.space_id) {
            count++
          }
        }
        graphListT.push({
          space_id: space.id,
          space_name: space.space_name,
          count,
        })
      }
      setGraphList(graphListT)
    }
  }, [locationsCategorizedBySpaceOperator, toursBooked])

  useEffect(() => {
    if (!searchQueries || searchQueries.length === 0) {
      return
    }
    const mostPopular = {}
    const noResults = {}
    searchQueries.map(({ space_type, team_size, results_count }) => {
      const key = space_type.replace(" ", "") + "_" + team_size
      if (mostPopular[key]) {
        mostPopular[key]++
      } else {
        mostPopular[key] = 1
      }
      if (!results_count) {
        if (noResults[key]) {
          noResults[key]++
        } else {
          noResults[key] = 1
        }
      }
      return null
    })

    const mostPopularArray = []
    for (const property in mostPopular) {
      mostPopularArray.push({
        query: property,
        count: mostPopular[property],
      })
    }
    const noResultsArray = []
    for (const property in noResults) {
      noResultsArray.push({
        query: property,
        count: noResults[property],
      })
    }
    const mostPopularSorted = mostPopularArray.sort(function (a, b) {
      return b.count - a.count
    })
    setMpList(mostPopularSorted)
    const noResultsSorted = noResultsArray.sort(function (a, b) {
      return b.count - a.count
    })

    const mpQuery =
      mostPopularSorted.length > 0 ? mostPopularSorted[0].query : ""
    if (mpQuery) {
      const array = mpQuery.split("_")
      const str1 =
        array[0] === "Privateoffices" ? "Private Offices" : "Dedicated Desks"
      const str2 = array[1]
      setMp(`${str1} for ${str2} ${Number(str2) > 1 ? "people" : "person"}`)
    }

    const nrQuery = noResultsSorted.length > 0 ? noResultsSorted[0].query : ""
    if (nrQuery) {
      const array = nrQuery.split("_")
      const str1 =
        array[0] === "Privateoffices" ? "Private Offices" : "Dedicated Desks"
      const str2 = array[1]
      setNr(`${str1} for ${str2} ${Number(str2) > 1 ? "people" : "person"}`)
    }
  }, [searchQueries])

  devFilterSearchQueries(searchQueries)

  const { themeColor } = themeConfig()

  const data = {
    labels:
      graphList && graphList.length > 0
        ? graphList.map((item) => item.space_name)
        : [],
    datasets: [
      {
        label: "",
        data:
          graphList && graphList.length > 0
            ? graphList.map((item) => item.count)
            : [],
        backgroundColor: `${themeColor}`,
        borderColor: `${themeColor}`,
        barThickness: 4,
        borderWidth: 2,
      },
    ],
  }

  return (
    <Layout title={"Dashboard"}>
      <div className="row">
        <div className="col-sm-12 mb-3">
          <h3>Tours and Search data</h3>
        </div>
        <div className="col-sm-4">
          <DashboardCard
            title={"Tours scheduled this month"}
            text={
              toursBooked && toursBooked.length > 0 ? toursBooked.length : "0"
            }
          />
        </div>
        <div className="col-sm-4">
          <DashboardCard
            title={"Most popular search query this month"}
            text={mp || "-"}
          />
        </div>
        <div className="col-sm-4">
          <DashboardCard
            title={"Most common search with no results"}
            text={nr || "-"}
          />
        </div>
        <div className="col-sm-8">
          <DashboardCard title={"Tours booked based on locations (this month)"}>
            <Bar data={data} options={options} />
          </DashboardCard>
        </div>
        <div className="col-sm-4">
          <DashboardCard title={"Popular Search Queries (this month)"}>
            {mpList && mpList.length > 0 ? (
              <table className="table dashboard-table">
                <thead>
                  <tr>
                    <th>Type</th>
                    <th>People</th>
                    <th>Number of visits</th>
                  </tr>
                </thead>
                <tbody>
                  {mpList.map((item, idx) => {
                    const query = item.query.split("_")
                    return (
                      <tr key={idx}>
                        <td>
                          {query[0] === "Privateoffices"
                            ? "Private Offices"
                            : "Dedicated Desks"}
                        </td>
                        <td>{query && query[1] ? query[1] : "-"}</td>
                        <td>{item.count}</td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            ) : (
              <p className={"text-muted text-center my-0"}>
                <small>No data available</small>
              </p>
            )}
          </DashboardCard>
        </div>
      </div>

      <Analytics />
    </Layout>
  )
}

export default ProspectAnalytics

const options = {
  plugins: {
    tooltip: {
      mode: "index",
      intersect: false,
      padding: 10,
      bodyFont: {
        family: "futura-pt",
        size: 16,
      },
      titleFont: {
        family: "futura-pt",
        size: 14,
      },
    },
    legend: {
      display: false,
    },
  },
  scales: {
    y: {
      beginAtZero: true,
    },
  },
  // The following will affect the vertical lines (xAxe) of your dataset
  xAxes: [
    {
      color: "black",
      gridLines: {
        // You can change the color, the dash effect, the main axe color, etc.
        borderDash: [8, 4],
        color: "rgba(0,0,0,0)",
      },
      ticks: {
        fontColor: "black",
        fontSize: 12,
        fontFamily: `"futura-pt", "sans-serif"`,
      },
    },
  ],

  // And this will affect the horizontal lines (yAxe) of your dataset
  yAxes: [
    {
      gridLines: {
        borderDash: [8, 4],
        color: "#dddddd",
      },
      ticks: {
        fontColor: "black",
        fontSize: 12,
        fontFamily: `"futura-pt", "sans-serif"`,
      },
    },
  ],
}
