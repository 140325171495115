import React from "react"

import Home from "@templates/Home"
import SEO from "@components/seo"

const HomePage = () => {
  return (
    <>
      <SEO title="Dashboard" />
      <Home />
    </>
  )
}
export default HomePage
